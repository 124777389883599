import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';

import {
  ILiquidacion,
  ILiquidacionResponse,
  LiquidacionDetalleResponse,
} from '../dashboard/liquidacion-folder/models/liquidacion';
import {
  ILiquidacionEditableValues,
  LiquidacionStatus,
} from '../dashboard/liquidacion-folder/models/extras';
import {BaseHttpService} from '../core/base-http.service';
declare var XLSX: any;

@Injectable({
  providedIn: 'root',
})
export class LiquidacionService extends BaseHttpService {
  private busquedaLiquidacion: any;
  private pageNumber: number;
  private bonificacionRendicion:boolean = false;
  private bonificacionLiquidacion:boolean = false;
  constructor(
    private _api: ApiService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private http: HttpClient
  ) {
    super();
  }

  setPageNumber(page: number) {
    //Guarda en el servicio y en el localStorage
    this.pageNumber = page;
    localStorage.setItem('pageLiquidaciones', page.toString());
  }

  getPageNumber() {
    //Devuelve del servicio, caso contrario del localStorage
    if (this.pageNumber != null) return this.pageNumber;
    var page =
      localStorage.getItem('pageLiquidaciones') != null
        ? localStorage.getItem('pageLiquidaciones')
        : 1;
    return page;
  }

  getHeadersLiquidaciones() {
    return {
      columns_show: [
        'Número',
        'Cliente',
        'Bruto',
        'Comisión',
        'Gastos',
        'Contracargo',
        'Fecha',
        'Distribuida',
        'Neto',
        'Banco',
        'Estado',
      ],
      columns_search: [
        'numero',
        'cliente',
        'importe_total',
        'importe_comision',
        'factura_comision',
        'contracargo',
        'fecha',
        'distribuida',
        'neto',
        'banco',
        'estado',
      ],
    };
  }

  getBusquedaLiquidacion() {
    return this.busquedaLiquidacion;
  }
  setBusquedaLiquidacion(busqueda) {
    //Guarda en el servicio
    this.busquedaLiquidacion = busqueda;
    // y en el localstorage
    localStorage.setItem(
      'parametrosConsulta',
      JSON.stringify(this.busquedaLiquidacion)
    );
  }

  getNombreCliente() {
    return JSON.parse(localStorage.getItem('nombreCliente'));
  }

  /**
   * Get an response object with an 'content'[] of 1 item if found the ID.
   * Else with return an response object with an empty 'content'[]
   * @param id
   * @returns
   */
  getByID(id: number) {
    return this.http.get<ILiquidacionResponse>(
      this.getUri(
        { pageNumber: 0, id },
        'liquidacion',
        'proceso',
        'get_liquidaciones'
      )
    );
  }

  /**
   * Obtiene el detalle de los movimientos o rendiciones asociados a la liquidacion
   * @param id
   * @returns
   */
  getDetailByID(id: number) {
    return this.http.get<LiquidacionDetalleResponse>(
      this.getUri(
        {},
        'cuenta_virtual',
        'proceso',
        'liquidacion_detalle',
        id + ''
      )
    );
  }

  changeProcessStatus(id: number | string, setStatus: LiquidacionStatus) {
    const stepUrl = {
      VERIFICADA: 'verificar_liquidacion',
      APROBADA: 'aprobar_liquidacion',
      REVISION: 'revizar_liquidacion',
    };
    return this.http.put(
      this.getUri({}, 'cuenta_virtual', 'proceso', stepUrl[setStatus], id + ''),
      {}
    );
  }

  updateLiquidacionValues(values: ILiquidacionEditableValues) {
    return this.http.post(
      this.getUri({}, 'cuenta_virtual', 'proceso', 'modificar_liquidacion'),
      values
    );
  }
 

  makePDFREfacto(ixd, idCliente){
    this._api
      .getLiquidacionesCliente(ixd, idCliente)
      .then((response: any) => {
        const doc = new jsPDF();

        const segundaFila = 40; 
        const anchoLinea = 195;
        let tercerFila = 50;
        let cuartaFila = 90;
        const columnPositions = {
          first: 10,
          second: 60,
          third: 80,
          fourth: 80,
          fifth: 105,
          sixth: 155,
          sep: 177
        };
        const primeraColumna = columnPositions.first;
        this.checkBonificaciones(response);
        this.addImageToPDF(doc, columnPositions.first);
        this.addTextToPDF(doc, response.id, columnPositions);
        this.addTituloToPDF(doc, response.cliente, columnPositions.first, segundaFila);
        this.addFechasToPDF(doc, response, columnPositions.sep, segundaFila);
        this.addImporteDepositadoToPDF(doc, response.importe_liquidado, columnPositions.first, tercerFila);
        
        
        
        doc.save('liquidacion.pdf');
      })
      .catch((error) => {
        console.log('ERROR', error);
      });
  }

  private checkBonificaciones(response: any) {
    // Verificación de bonificación en la liquidación
    if (response.importe_bonificacion_comision != null) {
      this.bonificacionLiquidacion = true;
    }

    // Verificación de bonificación en la rendición
    let rendicion_list = response.rendicion_list;
    rendicion_list.forEach(item => {
      if (item.importe_bonificacion_comision != null) {
        this.bonificacionRendicion = true;
      }
    });
  }
  private addImageToPDF(doc: jsPDF, positionX: number) {
    doc.addImage('../../assets/img/helipagos-color.png', 'PNG', positionX, 15, 35, 8);
  }
  private addTextToPDF(doc: jsPDF, id: number, columns: any) {
    doc.setFontSize(10);
    doc.setFont('Helvetica', 'bold');
    doc.text('Liquidación Nro.', 135, 20);

    // Texto dinámico con buenas prácticas
    doc.setFontSize(18);
    doc.setFont('Helvetica', 'bold');
    doc.text(id ? id.toString() : 'N/A', columns.sep, 22);  // Asegurarse que `id` es dinámico
  }
   private addFechasToPDF(doc: jsPDF, response: any, sepColumna: number, segundaFila: number) {
    doc.setFont('Helvetica', 'bold');
    doc.setFontSize(9);

    // Fechas dinámicas usando DatePipe
    const fechaStart = this.datePipe.transform(response.fecha_start, 'd-M-yy');
    const fechaEnd = this.datePipe.transform(response.fecha_end, 'd-M-yy');
    const fechaEmision = this.datePipe.transform(response.fecha, 'd-M-yy');

    // Añadir las fechas al PDF
    doc.text(`${fechaStart} a ${fechaEnd}`, sepColumna, segundaFila - 1); // Período
    doc.text(fechaEmision, sepColumna, segundaFila + 3); // Emisión
    doc.text(fechaEmision, sepColumna, segundaFila + 7); // Depósito

    // Añadir los títulos correspondientes
    doc.setFont('Helvetica', 'normal');
    doc.text('Período', 135, segundaFila - 1);
    doc.text('Emisión', 135, segundaFila + 3);
    doc.text('Depósito', 135, segundaFila + 7);
  }
  private addTituloToPDF(doc: jsPDF, cliente: string, primeraColumna: number, segundaFila: number) {
    const titulo = doc.setFontSize(15).splitTextToSize(cliente, 100); // Título dinámico del cliente
    doc.text(titulo, primeraColumna, segundaFila);
  }
  private addImporteDepositadoToPDF(doc: jsPDF, importe: number, primeraColumna: number, tercerFila: number) {
    if (importe != null) {
      // Dibujar rectángulo
      doc.setDrawColor(0);
      doc.setFillColor(245, 245, 245);
      doc.rect(primeraColumna, tercerFila, 190, 30, 'F'); // Rectángulo

      // Texto del título "Importe depositado"
      doc.setFont('Helvetica', 'normal');
      doc.setFontSize(12);
      doc.text('Importe depositado', 105, tercerFila + 5, null, 'center');

      // Formatear y añadir el importe depositado
      const formattedImporte = this.currencyPipe.transform(
        importe / 100,  // Dividir entre 100 si es centavos
        'CAD',          // Simboliza que es CAD
        '$',            // Simbolo de moneda
        '2.2-2',        // Formato con dos decimales
        'es-ARS'        // Formato regional (puedes cambiar según el caso)
      );

      doc.setFontSize(30);
      doc.setFont('Helvetica', 'bold');
      doc.setTextColor(83, 186, 171);
      doc.text(formattedImporte || '$0.00', 105, tercerFila + 20, null, 'center'); // Añadir el importe formateado
    }
  }
  calculateResumen(response: any) {
    let resumenConceptos = [];
    let resumenConceptos2 = [];
    let resumenValores2 = [];
    let importeCuentasConceptos = [];
    let importeCuentasValores = [];
    let retenciones = [];
    let retencionesValores = [];
    let resumenDes = [];

    if (response.importe_neto_cuenta_a != null) {
      importeCuentasConceptos.push('Importe cuenta A');
      importeCuentasValores.push(this.formatCurrency(response.importe_neto_cuenta_a));
    }

    if (response.importe_neto_cuenta_b != null) {
      importeCuentasConceptos.push('Importe cuenta B');
      importeCuentasValores.push(this.formatCurrency(response.importe_neto_cuenta_b));
    }

    if (response.importe_iva != null) {
      resumenConceptos2.push('IVA 21,00%');
      resumenValores2.push('- ' + this.formatCurrency(response.importe_iva));
      resumenDes.push('Impuestos');
    }

    if (response.importe_iibb_misiones != null) {
      resumenConceptos2.push('IIBB Misiones 3,31%');
      resumenValores2.push('- ' + this.formatCurrency(response.importe_iibb_misiones));
      resumenDes.push('Percepciones');
    }

    if (response.importe_sirtac != null) {
      retenciones.push('SIRTAC ' + response.alicuota_sirtac + '%');
      retencionesValores.push('- ' + this.formatCurrency(response.importe_sirtac));
    }

    if (response.importe_ret_iva != null) {
      retenciones.push('Ret. IVA ');
      retencionesValores.push('- ' + this.formatCurrency(response.importe_ret_iva));
    }

    if (response.importe_ret_ganancia != null) {
      retenciones.push('Ret. ganancias ');
      retencionesValores.push('- ' + this.formatCurrency(response.importe_ret_ganancia));
    }

    return {
      resumenConceptos,
      resumenConceptos2,
      resumenValores2,
      importeCuentasConceptos,
      importeCuentasValores,
      retenciones,
      retencionesValores,
      resumenDes
    };
  }
  formatCurrency(amount: number) {
    return this.currencyPipe.transform(amount / 100, 'CAD', '$', '2.2-2', 'es-ARS');
  }
  addResumenToDocument(doc: jsPDF, resumenData: any, fila: number, primeraColumna: number) {
    this.renderConceptos(doc, resumenData.resumenConceptos, resumenData.resumenValores2, primeraColumna, fila);
    this.renderImporteCuentas(doc, resumenData.importeCuentasConceptos, resumenData.importeCuentasValores, primeraColumna, fila);
    this.renderResumenConceptos2(doc, resumenData.resumenConceptos2, resumenData.resumenValores2, resumenData.resumenDes, primeraColumna, fila);
    this.renderRetenciones(doc, resumenData.retenciones, resumenData.retencionesValores, primeraColumna, fila);
  }
  renderConceptos(doc: jsPDF, conceptos: string[], valores: string[], primeraColumna: number, fila: number) {
    conceptos.forEach((concepto, index) => {
      fila += 8;
      doc.text(concepto, primeraColumna, fila);
      doc.text(valores[index], 178, fila);
      this.drawLine(doc, primeraColumna, fila);
    });
  }
  renderImporteCuentas(doc: jsPDF, conceptos: string[], valores: string[], primeraColumna: number, fila: number) {
    conceptos.forEach((concepto, index) => {
      fila += 8;
      doc.text(concepto, primeraColumna, fila);
      doc.text(valores[index], 178, fila);
      this.drawLine(doc, primeraColumna, fila);
    });
  }
  renderResumenConceptos2(doc: jsPDF, conceptos: string[], valores: string[], resumenDes: string[], primeraColumna: number, fila: number) {
    conceptos.forEach((concepto, index) => {
      fila += 8.5;
      doc.text(concepto, primeraColumna, fila);
      doc.setFont('Helvetica', 'normal');
      doc.setFontSize(6.8);
      doc.text(resumenDes[index], primeraColumna, fila - 3.5);
      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(8);
      doc.text(valores[index], 178, fila);
      this.drawLine(doc, primeraColumna, fila);
    });
  }
  renderRetenciones(doc: jsPDF, conceptos: string[], valores: string[], primeraColumna: number, fila: number) {
    if (conceptos.length) {
      fila += 6;
      doc.setFont('Helvetica', 'normal');
      doc.setFontSize(6.8);
      doc.text('Retenciones', primeraColumna, fila);
      this.drawLine(doc, primeraColumna, fila);
      conceptos.forEach((concepto, index) => {
        fila += 5;
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(8);
        doc.text(concepto, primeraColumna, fila);
        doc.text(valores[index], 178, fila);
      });
    }
  }
  drawLine(doc: jsPDF, primeraColumna: number, fila: number) {
    const ejeX = fila - 6;
    doc.setLineWidth(0.1);
    doc.line(primeraColumna, ejeX, 200, ejeX);
  }
 


  makePDF(ixd, idCliente) {
    this._api
      .getLiquidacionesCliente(ixd, idCliente)
      .then((response: any) => {
        //console.log("response", response);
        const doc = new jsPDF();
        //Columnas
        const primera_columna = 10,
          segunda_columna = 60,
          tercer_columna = 80,
          cuarta_columna = 80,
          quinta_columna = 105,
          sexta_columna = 155,
          sep_columna = 177;
         

        //RENDICIÓN
        doc.addImage(
          '../../assets/img/helipagos-color.png',
          'PNG',
          primera_columna,
          15,
          35,
          8
        );
        doc.setFontSize(10);
        doc.setFont('Helvetica', 'bold');
        doc.text('Liquidación Nro.', 135, 20);
        doc.setFontSize(18);
        doc.setFont('Helvetica', 'bold');
        doc.text(response.id.toString(), sep_columna, 22); //EL ID

        if(response.importe_bonificacion_comision != null){
          this.bonificacionLiquidacion = true;
        }
        
        let rendicion_list = response.rendicion_list;
        rendicion_list.forEach(item => {
          if (item.importe_bonificacion_comision != null) {
            this.bonificacionRendicion = true;
          }
        });

        //TITULO
        const segunda_fila = 40,
          anchoLinea = 195;
        const titulo = doc
          .setFontSize(15)
          .splitTextToSize(response.cliente, 100);
        doc.text(titulo, primera_columna, segunda_fila);

        // Tipo rendición - Periodo - Emisión - Depósito Valores
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(9);

        // let tipoRendicion = null;
        // if(ixd.distribuida == "Sí") tipoRendicion = "Distribuida"; else tipoRendicion = "Simple";

        doc.text(
          this.datePipe.transform(response.fecha_start, 'd-M-yy') +
            ' a ' +
            this.datePipe.transform(response.fecha_end, 'd-M-yy'),
          170,
          segunda_fila - 1
        ); //Periodo
        //doc.text(  this.datePipe.transform(new Date(), "d-M-yy")  , 170, segunda_fila + 3); //Emisión
        //doc.text( this.datePipe.transform(new Date(), "d-M-yy") , 170, segunda_fila + 7); //Depósito
        doc.text(
          this.datePipe.transform(response.fecha, 'd-M-yy'),
          170,
          segunda_fila + 3
        ); //Emisión
        doc.text(
          this.datePipe.transform(response.fecha, 'd-M-yy'),
          170,
          segunda_fila + 7
        ); //Depósito
        // Tipo rendición - Periodo - Emisión - Depósito Titulos
        doc.setFont('Helvetica', 'normal');

        doc.text('Período', 135, segunda_fila - 1);
        doc.text('Emisión', 135, segunda_fila + 3);
        doc.text('Depósito', 135, segunda_fila + 7);

        //Rectángulo con importe
        let tercer_fila = 50;
        doc.setDrawColor(0);
        doc.setFillColor(245, 245, 245);
        doc.rect(primera_columna, tercer_fila, 190, 30, 'F');

        doc.text(
          'Importe depositado',
          105,
          tercer_columna - 20,
          null,
          'center'
        );
        doc.setFontSize(30);
        doc.setFont('Helvetica', 'bold');
        doc.setTextColor(83, 186, 171);

        doc.text(
          this.currencyPipe.transform(
            response.importe_liquidado / 100,
            'CAD',
            '$',
            '2.2-2',
            'es-ARS'
          ),
          105,
          tercer_columna - 7,
          null,
          'center'
        );
        // doc.text("Depósito", tercer_columna, segunda_fila + 11);
        let rendicion = response.rendicion_list;
        let rendicionCant = 0;
        for (let r = 0; r <= rendicion.length - 1; r++) {
          rendicionCant += rendicion[r].cantidad;
        }

        // //TABLA - RESUMEN
        let cuarta_fila = 90;
        doc.setFont('Helvetica', 'normal');
        doc.setFontSize(8);
        doc.setTextColor(0, 0, 0);
        doc.text('Resumen', primera_columna, cuarta_fila);
        //columnas
        const conceptos = [];
        doc.setFont('Helvetica', 'bold');
        conceptos.push('Importe percibido');
        conceptos.push('Comisión Helipagos');
        if(this.bonificacionLiquidacion){
          conceptos.push('Bonificación');
        }
        conceptos.push('Gastos');
        conceptos.push('Contracargos');
        conceptos.push('Devoluciones');
        conceptos.push('Cantidad de operaciones');
        
        let resumen_conceptos = conceptos;

        let resumen_conceptos2: any = [];
        let resumen_valores2: any = [];

        let importe_cuentas_conceptos: any = [];
        let importe_cuentas_valores: any = [];

        let retenciones: any = [];
        let retenciones_valores: any = [];

        let resumen_des: any = [];

       if(response.importe_neto_cuenta_a != null){
        importe_cuentas_conceptos.push('Importe cuenta A');
        importe_cuentas_valores.push(
              this.currencyPipe.transform(
                response.importe_neto_cuenta_a / 100,
                'CAD',
                '$',
                '2.2-2',
                'es-ARS'
              )
          );
        }

        if(response.importe_neto_cuenta_b != null){
          importe_cuentas_conceptos.push('Importe cuenta B');
          importe_cuentas_valores.push(
              this.currencyPipe.transform(
                response.importe_neto_cuenta_b / 100,
                'CAD',
                '$',
                '2.2-2',
                'es-ARS'
              )
          );
        }

        if (response.importe_iva != null) {
          resumen_conceptos2.push('IVA 21,00%');
          resumen_valores2.push(
            '- ' +
              this.currencyPipe.transform(
                response.importe_iva / 100,
                'CAD',
                '$',
                '2.2-2',
                'es-ARS'
              )
          );
          resumen_des.push('Impuestos');
        }
        if (response.importe_iibb_misiones != null) {
          resumen_conceptos2.push('IIBB Misiones 3,31%');
          resumen_valores2.push(
            '- ' +
              this.currencyPipe.transform(
                response.importe_iibb_misiones / 100,
                'CAD',
                '$',
                '2.2-2',
                'es-ARS'
              )
          );
          resumen_des.push('Percepciones');
        }

        if (response.importe_sirtac != null) {
          retenciones.push('SIRTAC ' + response.alicuota_sirtac + '%');
          retenciones_valores.push(
            '- ' +
              this.currencyPipe.transform(
                response.importe_sirtac / 100,
                'CAD',
                '$',
                '2.2-2',
                'es-ARS'
              )
          );
        }
        if (response.importe_ret_iva != null) {
          retenciones.push('Ret. IVA ');
          retenciones_valores.push(
            '- ' +
              this.currencyPipe.transform(
                response.importe_ret_iva / 100,
                'CAD',
                '$',
                '2.2-2',
                'es-ARS'
              )
          );
        }
        if (response.importe_ret_ganancia != null) {
          retenciones.push('Ret. ganancias ');
          retenciones_valores.push(
            '- ' +
              this.currencyPipe.transform(
                response.importe_ret_ganancia / 100,
                'CAD',
                '$',
                '2.2-2',
                'es-ARS'
              )
          );
        }

        const valores = [];
        valores.push(this.currencyPipe.transform(response.importe_total / 100,
          'CAD',
          '$',
          '2.2-2',
          'es-ARS'
        ));

        valores.push( '- ' +
        this.currencyPipe.transform(
          response.importe_comision / 100,
          'CAD',
          '$',
          '2.2-2',
          'es-ARS'
        ));
        if(this.bonificacionLiquidacion){
            valores.push(
            this.currencyPipe.transform(
              response.importe_bonificacion_comision / 100,
              'CAD',
              '$',
              '2.2-2',
              'es-ARS'
          ));
        }
        

        valores.push('- ' +this.currencyPipe.transform(
          response.importe_gasto / 100,
          'CAD',
          '$',
          '2.2-2',
          'es-ARS'
        ));
    
          valores.push('- '
          +
            this.currencyPipe.transform(
              response.importe_contracargo / 100,
              'CAD',
              '$',
              '2.2-2',
              'es-ARS'
            ));
            valores.push('- $ 0.00 (0)');
            valores.push(' ' + rendicionCant);
       // console.log(valores);

        let resumen_valores =valores;
        //console.log('Contracargo ' + this.currencyPipe.transform((this.liquidacion.importe_contracargo/100), 'CAD', '$', '2.2-2', 'es-ARS'));
        resumen_conceptos.forEach((concepto, index) => {
          cuarta_fila = cuarta_fila + 8;
          //titulos
          doc.text(concepto, primera_columna, cuarta_fila);
          //valores
          doc.text(resumen_valores[index], 178, cuarta_fila);
          //Lineas
          const ejeX = cuarta_fila - 6;
          doc.setLineWidth(0.1); //Espesor de la línea
          doc.line(primera_columna, ejeX, 200, ejeX);
        });

        importe_cuentas_conceptos.forEach((concepto, index) => {
          cuarta_fila = cuarta_fila + 8;
          //titulos
          doc.text(concepto, primera_columna, cuarta_fila);
          //valores
          doc.text(importe_cuentas_valores[index], 178, cuarta_fila);
          //Lineas
          const ejeX = cuarta_fila - 6;
          doc.setLineWidth(0.1); //Espesor de la línea
          doc.line(primera_columna, ejeX, 200, ejeX);
        });


         resumen_conceptos2.forEach((concepto, index) => {
          cuarta_fila = cuarta_fila + 8.5;
          //titulos
          doc.text(concepto, primera_columna, cuarta_fila);
          doc.setFont('Helvetica', 'normal');
          doc.setFontSize(6.8);
          //valores
          doc.text(resumen_des[index], primera_columna, cuarta_fila - 3.5);
          doc.setFont('Helvetica', 'bold');
          doc.setFontSize(8);
          doc.text(resumen_valores2[index], 178, cuarta_fila);

          //Lineas
          const ejeX = cuarta_fila - 6;
          doc.setLineWidth(0.1); //Espesor de la línea
          doc.line(primera_columna, ejeX, 200, ejeX);
        });

        if (retenciones.length != 0) {
          const ejeX = cuarta_fila + 3;
          doc.setLineWidth(0.1); //Espesor de la línea
          doc.line(primera_columna, ejeX, 200, ejeX);
          doc.setFont('Helvetica', 'normal');
          doc.setFontSize(6.8);
          doc.text('Retenciones', primera_columna, cuarta_fila + 6);
        }
        retenciones.forEach((concepto, index) => {
          cuarta_fila = cuarta_fila + 5;
          //titulos
          doc.setFont('Helvetica', 'bold');
          doc.setFontSize(8);
          doc.text(concepto, primera_columna, cuarta_fila + 5);
          //valores

          doc.text(retenciones_valores[index], 178, cuarta_fila + 5);
        });

        // //TABLA - DISTRIBUCIÓN
       /* let quinta_fila;
        if (retenciones.length == 0 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 0) {
          quinta_fila = 150;
        }
        else if (retenciones.length == 0 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 1) {
          quinta_fila = 153;
        }
        else if (retenciones.length == 0 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 2) {
          quinta_fila = 160;
        }
        else if (retenciones.length == 1 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 0) {
          quinta_fila = 154;
        }
        else if (retenciones.length == 1 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 0) {
          quinta_fila = 160;
        }
        else if (retenciones.length == 2 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 0) {
          quinta_fila = 160;
        }
        else if (retenciones.length == 3 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 0) {
          quinta_fila = 165;
        }
        else if (retenciones.length == 0 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 0) {
          quinta_fila = 153;
        }
        else if (retenciones.length == 0 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 0) {
          quinta_fila = 162;
        }
        else if (retenciones.length == 1 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 1) {
          quinta_fila = 162;
        }
        else if (retenciones.length == 1 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 2) {
          quinta_fila = 170;
        }
        else if (retenciones.length == 2 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 0) {
          quinta_fila = 170;
        }////////////////////////////////
        else if (retenciones.length == 1 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 0) {
          quinta_fila = 170;
        }
        else if (retenciones.length == 2 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 1) {
          quinta_fila = 170;
        }
        else if (retenciones.length == 3 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 1) {
          quinta_fila = 175;
        }
        else if (retenciones.length == 2 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 0) {
          quinta_fila = 175;
        }
        else if (retenciones.length == 2 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 2) {
          quinta_fila = 175;
        } 
        else if (retenciones.length == 3 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 2) {
          quinta_fila = 180;
        }  
        else if (retenciones.length == 1 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 1) {
          quinta_fila = 170;
        }
        else if (retenciones.length == 1 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 1) {
          quinta_fila = 178;
        }
        else if (retenciones.length == 3 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 0) {
          quinta_fila = 178;
        }
        else if (retenciones.length == 2 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 1) {
          quinta_fila = 178;
        }
        else if (retenciones.length == 2 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 1) {
          quinta_fila = 183;
        } //////////////////////////////////////////////
        else if (retenciones.length == 2 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 2) {
          quinta_fila = 192;
        } 
        else if (retenciones.length == 3 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 1) {
          quinta_fila = 180;
        }
        else if (retenciones.length == 3 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 0) {
          quinta_fila = 180;
        }
        else if (retenciones.length == 3 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 1) {
          quinta_fila = 192;
        } 
        else if (retenciones.length == 3 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 2) {
          quinta_fila = 192;
        } 
        else if (retenciones.length == 1 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 2) {
          quinta_fila = 178;
        }
        else if (retenciones.length == 1 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 2) {
          quinta_fila = 188;
        }
        else if (retenciones.length == 2 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 2) {
          quinta_fila = 183;
        }
        else if (retenciones.length == 3 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 2) {
          quinta_fila = 192;
        } */
        let quinta_fila = this.calcularQuintaFila(retenciones, resumen_conceptos2, importe_cuentas_valores);

        //TABLA - DETALLES
        doc.setFont('Helvetica', 'bold');
        
        //titulos horizontales
        
        if(this.bonificacionRendicion){
          doc.text('Detalles', primera_columna, quinta_fila+8);
          doc.text('Descripción', primera_columna, quinta_fila + 15);
          doc.text('Cant.', 40, quinta_fila +15);
          doc.text('Bruto', 50, quinta_fila + 15);
          doc.text('Comisión + IVA', 73.5, quinta_fila + 15);
          doc.text('Gastos + IVA', 97, quinta_fila + 15);
          doc.text('Bonificación', 120, quinta_fila + 15);
          doc.text('Contracargo', 141, quinta_fila + 15);
          doc.text('Fecha', 161, quinta_fila + 15);
          doc.text('Neto', 180, quinta_fila + 15);
          doc.line(primera_columna, quinta_fila + 10, 200, quinta_fila + 10);
          doc.line(primera_columna, quinta_fila + 18, 200, quinta_fila + 18);
        }
        else{
          doc.text('Detalles', primera_columna, quinta_fila);
          doc.text('Descripción', primera_columna, quinta_fila + 8);
          doc.text('Cant.', 40, quinta_fila + 8);
          doc.text('Bruto', 55, quinta_fila + 8);
          doc.text('Comisión + IVA', cuarta_columna, quinta_fila + 8);
          doc.text('Gastos + IVA', quinta_columna, quinta_fila + 8);
          doc.text('Contracargo', 130, quinta_fila + 8);
          doc.text('Fecha', sexta_columna, quinta_fila + 8);
          doc.text('Neto', sep_columna, quinta_fila + 8);
          doc.line(primera_columna, quinta_fila + 2, 200, quinta_fila + 2);
          doc.line(primera_columna, quinta_fila + 10, 200, quinta_fila + 10);
        }
        
        

        /* quinta_fila = quinta_fila + 8;
              // console.log("leng " + rendicion_list.length);
                  for (let i=0; i < rendicion_list.length ; i++){
                    quinta_fila = quinta_fila + 8;
                    doc.setFont("Helvetica", "normal");
                    doc.setFontSize(9);

                      doc.text('Rendición Nº ' + rendicion_list[i].id.toString(), primera_columna, quinta_fila);
                      doc.text(rendicion_list[i].cantidad.toString(),40,quinta_fila);
                      doc.text(this.currencyPipe.transform((rendicion_list[i].importe_total/100), 'CAD', '$', '2.2-2', 'es-ARS'), 55, quinta_fila);
                      doc.text(this.currencyPipe.transform((rendicion_list[i].importe_comision/100), 'CAD', '$', '2.2-2', 'es-ARS'), cuarta_columna, quinta_fila);
                      doc.text(this.currencyPipe.transform((rendicion_list[i].importe_gasto/100), 'CAD', '$', '2.2-2', 'es-ARS'), quinta_columna, quinta_fila);
                      doc.text(this.currencyPipe.transform((rendicion_list[i].importe_contracargo/100), 'CAD', '$', '2.2-2', 'es-ARS'), 130, quinta_fila);
                      doc.text(this.datePipe.transform(rendicion_list[i].fecha, "d/MM/yy"), sexta_columna, quinta_fila);
                      doc.text(this.currencyPipe.transform((rendicion_list[i].importe_liquidado/100), 'CAD', '$', '2.2-2', 'es-ARS'), sep_columna, quinta_fila);

                  }

                let sexta_fila;
                if (rendicion_list.length < 15 ){
                  sexta_fila =  162 +  (rendicion_list.length * 8);
                }else{

                  sexta_fila =  283;
                }*/

        let sexta_fila;

      /*  let totalpagact;
        totalpagact = 14;
        let aux;
        aux = 0;
        if(this.bonificacionRendicion){
          if (retenciones.length == 0 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 0) {//
            quinta_fila = 175;
          } else if (retenciones.length == 0 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 1) {//
            quinta_fila = 178;
            totalpagact = totalpagact - 1;
            aux++;
          }  else if (retenciones.length == 0 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 2) {//
            quinta_fila = 182;
            totalpagact = totalpagact - 1;
            aux++;
          } else if(retenciones.length == 1 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 0){
            quinta_fila = 178;
            totalpagact = totalpagact - 1;
            aux++;
          }else if(retenciones.length == 1 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 0){
            quinta_fila = 182;
            totalpagact = totalpagact - 1;
            aux++;
          }
            else if(retenciones.length == 2 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 0){
            quinta_fila = 182;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 3 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 0) {//
            quinta_fila = 187 ;
            totalpagact = totalpagact - 1;
            aux++;
          }
          else if (retenciones.length == 1 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 0) {//
            quinta_fila = 192 ;
            totalpagact = totalpagact - 1;
            aux++;
          }
          else if (retenciones.length == 2 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 0) {//
            quinta_fila = 192 ;
            totalpagact = totalpagact - 1;
            aux++;
          }
          else if (retenciones.length == 2 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 0) {//
            quinta_fila = 198 ;
            totalpagact = totalpagact - 1;
            aux++;
          }
          
          else if (retenciones.length == 0 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 0) {//
            quinta_fila = 178;
            totalpagact = totalpagact - 1;
            aux++;
          }
          else if (retenciones.length == 0 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 0) {//
            quinta_fila = 184;
            totalpagact = totalpagact - 1;
            aux++;
          }
          else if (retenciones.length == 1 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 1) {//
            quinta_fila = 184;
            totalpagact = totalpagact - 1;
            aux++;
          }
          else if (retenciones.length == 1 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 2) {//
            quinta_fila = 192;
            totalpagact = totalpagact - 1;
            aux++;
          }  
          else if (retenciones.length == 2 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 1) {//
            quinta_fila = 192;
            totalpagact = totalpagact - 1;
            aux++;
          } 
          else if (retenciones.length == 3 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 1) {//
            quinta_fila = 198;
            totalpagact = totalpagact - 2;
            aux++;
          }  
          else if (retenciones.length == 2 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 2) {//
            quinta_fila = 198;
            totalpagact = totalpagact - 3;
            aux++;
          }  
          else if (retenciones.length == 3 && resumen_conceptos2.length == 0 && importe_cuentas_valores.length == 2) {//
            quinta_fila = 205;
            totalpagact = totalpagact - 3;
            aux++;
          }  
          else if (retenciones.length == 1 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 1) {//
            quinta_fila = 192;
            totalpagact = totalpagact - 2;
            aux++;
          } 
          else if (retenciones.length == 1 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 1) {//
            quinta_fila = 200;
            totalpagact = totalpagact - 3;
            aux++;
          } 
          else if (retenciones.length == 2 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 1) {//
            quinta_fila = 200;
            totalpagact = totalpagact - 3;
            aux++;
          } 
          else if (retenciones.length == 3 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 0) {//
            quinta_fila = 200;
            totalpagact = totalpagact - 3;
            aux++;
          } 
          else if (retenciones.length == 2 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 1) {//
            quinta_fila = 208;
            totalpagact = totalpagact - 3;
            aux++;
          } 
          else if (retenciones.length == 2 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 2) {//
            quinta_fila = 215;
            totalpagact = totalpagact - 3;
            aux++;
          }  
          else if (retenciones.length == 3 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 1) {//
            quinta_fila = 205;
            totalpagact = totalpagact - 3;
            aux++;
          } 
          else if (retenciones.length == 3 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 0) {//
            quinta_fila = 205;
            totalpagact = totalpagact - 3;
            aux++;
          } 
          else if (retenciones.length == 3 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 1) {//
            quinta_fila = 215;
            totalpagact = totalpagact - 3;
            aux++;
          } 
          else if (retenciones.length == 3 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 2) {//
            quinta_fila = 215;
            totalpagact = totalpagact - 3;
            aux++;
          } 
          else if (retenciones.length == 1 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 2) {//
            quinta_fila = 200;
            totalpagact = totalpagact - 3;
            aux++;
          } 
          else if (retenciones.length == 1 && resumen_conceptos2.length == 2 && importe_cuentas_valores.length == 2) {//
            quinta_fila = 210;
            totalpagact = totalpagact - 3;
            aux++;
          } 
          else if (retenciones.length == 2 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 2) {//
            quinta_fila = 205;
            totalpagact = totalpagact - 3;
            aux++;
          } 
          else if (retenciones.length == 3 && resumen_conceptos2.length == 1 && importe_cuentas_valores.length == 2) {//
            quinta_fila = 215;
            totalpagact = totalpagact - 3;
            aux++;
          }  
         
          /*if (retenciones.length == 0 && resumen_conceptos2.length == 0) {//
            quinta_fila = 175;
          } else if (retenciones.length == 0 && resumen_conceptos2.length == 1) {//
            quinta_fila = 182.5;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 0 && resumen_conceptos2.length == 2) {//
            quinta_fila = 188;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 1 && resumen_conceptos2.length == 0) {//
            quinta_fila = 185;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 2 && resumen_conceptos2.length == 0) {//
            quinta_fila = 188;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 1 && resumen_conceptos2.length == 1) {//
            quinta_fila = 192;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 1 && resumen_conceptos2.length == 2) {//
            quinta_fila = 198;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 2 && resumen_conceptos2.length == 2) {//
            quinta_fila = 205;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 2 && resumen_conceptos2.length == 1) {//
            quinta_fila = 198;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 3 && resumen_conceptos2.length == 0) {//
            quinta_fila = 192.5;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 3 && resumen_conceptos2.length == 1) {//
            quinta_fila = 198.5;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 3 && resumen_conceptos2.length == 2) {//
            quinta_fila = 210;
            totalpagact = totalpagact - 1;
            aux++;
          }*/
        /*}else{
          if (retenciones.length == 0 && resumen_conceptos2.length == 0) {
            quinta_fila = 165;
          } else if (retenciones.length == 0 && resumen_conceptos2.length == 1) {
            quinta_fila = 175;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 0 && resumen_conceptos2.length == 2) {
            quinta_fila = 180;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 1 && resumen_conceptos2.length == 0) {
            quinta_fila = 175;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 2 && resumen_conceptos2.length == 0) {
            quinta_fila = 178;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 1 && resumen_conceptos2.length == 1) {
            quinta_fila = 182;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 1 && resumen_conceptos2.length == 2) {
            quinta_fila = 190;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 2 && resumen_conceptos2.length == 2) {
            quinta_fila = 195;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 2 && resumen_conceptos2.length == 1) {
            quinta_fila = 190;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 3 && resumen_conceptos2.length == 0) {
            quinta_fila = 185;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 3 && resumen_conceptos2.length == 1) {
            quinta_fila = 190;
            totalpagact = totalpagact - 1;
            aux++;
          } else if (retenciones.length == 3 && resumen_conceptos2.length == 2) {
            quinta_fila = 200;
            totalpagact = totalpagact - 1;
            aux++;
          }
        }*/

          let totalpagact = 14;
          let aux = 0;
          quinta_fila = 175; 

        if (this.bonificacionRendicion) {
            const combinaciones = {
                '0-0-0': { quinta_fila: 175, totalpagact_delta: 0, aux_delta: 0 },
                '0-0-1': { quinta_fila: 178, totalpagact_delta: -1, aux_delta: 1 },
                '0-0-2': { quinta_fila: 182, totalpagact_delta: -1, aux_delta: 1 },
                '1-0-0': { quinta_fila: 178, totalpagact_delta: -1, aux_delta: 1 },
                '1-1-0': { quinta_fila: 182, totalpagact_delta: -1, aux_delta: 1 },
                '2-0-0': { quinta_fila: 182, totalpagact_delta: -1, aux_delta: 1 },
                '3-0-0': { quinta_fila: 187, totalpagact_delta: -1, aux_delta: 1 },
                '1-2-0': { quinta_fila: 192, totalpagact_delta: -1, aux_delta: 1 },
                '2-1-0': { quinta_fila: 192, totalpagact_delta: -1, aux_delta: 1 },
                '2-2-0': { quinta_fila: 198, totalpagact_delta: -1, aux_delta: 1 },
                '0-1-0': { quinta_fila: 178, totalpagact_delta: -1, aux_delta: 1 },
                '0-2-0': { quinta_fila: 184, totalpagact_delta: -1, aux_delta: 1 },
                '1-0-1': { quinta_fila: 184, totalpagact_delta: -1, aux_delta: 1 },
                '1-0-2': { quinta_fila: 192, totalpagact_delta: -1, aux_delta: 1 },
                '2-0-1': { quinta_fila: 192, totalpagact_delta: -1, aux_delta: 1 },
                '3-0-1': { quinta_fila: 198, totalpagact_delta: -2, aux_delta: 1 },
                '2-0-2': { quinta_fila: 198, totalpagact_delta: -3, aux_delta: 1 },
                '3-0-2': { quinta_fila: 205, totalpagact_delta: -3, aux_delta: 1 },
                '1-1-1': { quinta_fila: 192, totalpagact_delta: -2, aux_delta: 1 },
                '1-2-1': { quinta_fila: 200, totalpagact_delta: -3, aux_delta: 1 },
                '2-1-1': { quinta_fila: 200, totalpagact_delta: -3, aux_delta: 1 },
                '3-1-0': { quinta_fila: 200, totalpagact_delta: -3, aux_delta: 1 },
                '2-2-1': { quinta_fila: 208, totalpagact_delta: -3, aux_delta: 1 },
                '2-2-2': { quinta_fila: 215, totalpagact_delta: -3, aux_delta: 1 },
                '3-1-1': { quinta_fila: 205, totalpagact_delta: -3, aux_delta: 1 },
                '3-2-0': { quinta_fila: 205, totalpagact_delta: -3, aux_delta: 1 },
                '3-2-1': { quinta_fila: 215, totalpagact_delta: -3, aux_delta: 1 },
                '3-2-2': { quinta_fila: 215, totalpagact_delta: -3, aux_delta: 1 },
                '1-1-2': { quinta_fila: 200, totalpagact_delta: -3, aux_delta: 1 },
                '1-2-2': { quinta_fila: 210, totalpagact_delta: -3, aux_delta: 1 },
                '2-1-2': { quinta_fila: 205, totalpagact_delta: -3, aux_delta: 1 },
                '3-1-2': { quinta_fila: 215, totalpagact_delta: -3, aux_delta: 1 },
                '0-1-2': { quinta_fila: 192, totalpagact_delta: -2, aux_delta: 1 },
                '0-2-2': { quinta_fila: 250, totalpagact_delta: -3, aux_delta: 1,},
                '0-1-1': { quinta_fila: 185, totalpagact_delta: -1, aux_delta: 1 },
                '0-2-1': { quinta_fila: 250, totalpagact_delta: -1, aux_delta: 1 }
            };

            const key = `${retenciones.length}-${resumen_conceptos2.length}-${importe_cuentas_valores.length}`;
            const result = combinaciones[key] || { quinta_fila: 175, totalpagact_delta: 0, aux_delta: 0 };
            quinta_fila = result.quinta_fila;
            totalpagact += result.totalpagact_delta;
            aux += result.aux_delta;
        }else{
              const combinaciones = {
                '0-0-0': { quinta_fila: 175, totalpagact_delta: 0, aux_delta: 0 },
                '0-0-1': { quinta_fila: 178, totalpagact_delta: -1, aux_delta: 1 },
                '0-0-2': { quinta_fila: 182, totalpagact_delta: -1, aux_delta: 1 },
                '1-0-0': { quinta_fila: 178, totalpagact_delta: -1, aux_delta: 1 },
                '1-1-0': { quinta_fila: 182, totalpagact_delta: -1, aux_delta: 1 },
                '2-0-0': { quinta_fila: 182, totalpagact_delta: -1, aux_delta: 1 },
                '3-0-0': { quinta_fila: 187, totalpagact_delta: -1, aux_delta: 1 },
                '1-2-0': { quinta_fila: 192, totalpagact_delta: -1, aux_delta: 1 },
                '2-1-0': { quinta_fila: 192, totalpagact_delta: -1, aux_delta: 1 },
                '2-2-0': { quinta_fila: 198, totalpagact_delta: -1, aux_delta: 1 },
                '0-1-0': { quinta_fila: 178, totalpagact_delta: -1, aux_delta: 1 },
                '0-2-0': { quinta_fila: 184, totalpagact_delta: -1, aux_delta: 1 },
                '1-0-1': { quinta_fila: 184, totalpagact_delta: -1, aux_delta: 1 },
                '1-0-2': { quinta_fila: 192, totalpagact_delta: -1, aux_delta: 1 },
                '2-0-1': { quinta_fila: 192, totalpagact_delta: -1, aux_delta: 1 },
                '3-0-1': { quinta_fila: 198, totalpagact_delta: -2, aux_delta: 1 },
                '2-0-2': { quinta_fila: 198, totalpagact_delta: -3, aux_delta: 1 },
                '3-0-2': { quinta_fila: 205, totalpagact_delta: -3, aux_delta: 1 },
                '1-1-1': { quinta_fila: 192, totalpagact_delta: -2, aux_delta: 1 },
                '1-2-1': { quinta_fila: 200, totalpagact_delta: -3, aux_delta: 1 },
                '2-1-1': { quinta_fila: 200, totalpagact_delta: -3, aux_delta: 1 },
                '3-1-0': { quinta_fila: 200, totalpagact_delta: -3, aux_delta: 1 },
                '2-2-1': { quinta_fila: 208, totalpagact_delta: -3, aux_delta: 1 },
                '2-2-2': { quinta_fila: 215, totalpagact_delta: -3, aux_delta: 1 },
                '3-1-1': { quinta_fila: 205, totalpagact_delta: -3, aux_delta: 1 },
                '3-2-0': { quinta_fila: 205, totalpagact_delta: -3, aux_delta: 1 },
                '3-2-1': { quinta_fila: 215, totalpagact_delta: -3, aux_delta: 1 },
                '3-2-2': { quinta_fila: 215, totalpagact_delta: -3, aux_delta: 1 },
                '1-1-2': { quinta_fila: 200, totalpagact_delta: -3, aux_delta: 1 },
                '1-2-2': { quinta_fila: 210, totalpagact_delta: -3, aux_delta: 1 },
                '2-1-2': { quinta_fila: 205, totalpagact_delta: -3, aux_delta: 1 },
                '3-1-2': { quinta_fila: 215, totalpagact_delta: -3, aux_delta: 1 },
                '0-1-2': { quinta_fila: 193, totalpagact_delta: -1, aux_delta: 1 },
                '0-2-2': { quinta_fila: 200, totalpagact_delta: -1, aux_delta: 1 },
                '0-1-1': { quinta_fila: 185, totalpagact_delta: -1, aux_delta: 1 },
                '0-2-1': { quinta_fila: 195, totalpagact_delta: -1, aux_delta: 1 }
            };

            const key = `${retenciones.length}-${resumen_conceptos2.length}-${importe_cuentas_valores.length}`;
            const result = combinaciones[key] || { quinta_fila: 175, totalpagact_delta: 0, aux_delta: 0 };
            quinta_fila = result.quinta_fila;
            totalpagact += result.totalpagact_delta;
            aux += result.aux_delta;
        }
       

        let totalren =
          rendicion_list.length + resumen_conceptos2.length + importe_cuentas_valores.length +(20 + aux);
        let x = totalren / 34; //1.11
        let totalpage = Math.floor(x);
        //let salto_aux;
        if (rendicion_list.length == totalpagact) {
          totalpagact = totalpagact - 1;
          //console.log(salto_aux);
        }
        //console.log(':::'+rendicion_list.length+' :::'+(totalpagact-1) );
        if (x - totalpage > 0) {
          totalpage++;
        }
        let i = 0;

        for (let p = 1; p <= totalpage; p++) {
           //let totalpagact = 15;

          // if(resumen_conceptos2.length == 0){
          //   quinta_fila = 165;

          // }else if(resumen_conceptos2.length == 1){
          //   quinta_fila = 170;

          // }else if(resumen_conceptos2.length == 2){
          //   quinta_fila = 180;

          // }else if(resumen_conceptos2.length == 3){
          //   quinta_fila = 190;

          // }
          if (p > 1) {
            totalpagact = 34;
            quinta_fila = 20;
          }
          for (let r = 0; r < totalpagact - 1; r++) {
            if (rendicion_list[i] != null) {
              //console.log('pagina: '+p + '     r: '+r + '    renglon: '+i+'    renglon: '+quinta_fila);

              doc.setFont('Helvetica', 'normal');
              doc.setFontSize(9);
              {
               
                if(this.bonificacionRendicion){
                  doc.text('Rendición Nº ' + rendicion_list[i].id.toString(),primera_columna,quinta_fila);
                  doc.text(rendicion_list[i].cantidad.toString(),40,quinta_fila);
                  doc.text(this.currencyPipe.transform(rendicion_list[i].importe_total / 100,'CAD','$','2.2-2','es-ARS'),50,quinta_fila);
                  doc.text(this.currencyPipe.transform(rendicion_list[i].importe_comision / 100,'CAD','$','2.2-2','es-ARS'),73.5,quinta_fila);
                  doc.text(this.currencyPipe.transform(rendicion_list[i].importe_gasto / 100,'CAD','$','2.2-2','es-ARS'),97,quinta_fila);
                  doc.text(this.currencyPipe.transform(rendicion_list[i].importe_bonificacion_comision / 100,'CAD','$','2.2-2','es-ARS'),120,quinta_fila);
                  doc.text(this.currencyPipe.transform(rendicion_list[i].importe_contracargo / 100,'CAD','$','2.2-2','es-ARS'),141,quinta_fila);
                  doc.text(this.datePipe.transform(rendicion_list[i].fecha, 'd/MM/yy'),161,quinta_fila);
                  doc.text(this.currencyPipe.transform(rendicion_list[i].importe_liquidado / 100,'CAD','$','2.2-2','es-ARS'),sep_columna,quinta_fila);
                }else{
                  doc.text('Rendición Nº ' + rendicion_list[i].id.toString(),primera_columna,quinta_fila);
                  doc.text(rendicion_list[i].cantidad.toString(),40,quinta_fila);
                  doc.text(this.currencyPipe.transform(rendicion_list[i].importe_total / 100,'CAD','$','2.2-2','es-ARS'),55,quinta_fila);
                  doc.text(this.currencyPipe.transform(rendicion_list[i].importe_comision / 100,'CAD','$','2.2-2','es-ARS'),cuarta_columna,quinta_fila);
                  doc.text(this.currencyPipe.transform(rendicion_list[i].importe_gasto / 100,'CAD','$','2.2-2','es-ARS'),quinta_columna,quinta_fila);
                  doc.text(this.currencyPipe.transform(rendicion_list[i].importe_contracargo / 100,'CAD','$','2.2-2','es-ARS'),130,quinta_fila);
                  doc.text(this.datePipe.transform(rendicion_list[i].fecha, 'd/MM/yy'),sexta_columna,quinta_fila);
                  doc.text(this.currencyPipe.transform(rendicion_list[i].importe_liquidado / 100,'CAD','$','2.2-2','es-ARS'),sep_columna,quinta_fila);
                }
                
                
              }
              quinta_fila = quinta_fila + 7.5;
              i++;
            }
          }
          if (p < totalpage) {
            doc.addPage();
            //console.log('SALTO pagina: '+p );
          }
        }

        // console.log("leng " + rendicion_list.length);

        sexta_fila = quinta_fila;

        if (rendicion_list.length <= 12) {
          //sexta_fila =  160 +  (rendicion_list.length * 8);
          doc.line(primera_columna, sexta_fila - 5, 200, sexta_fila - 5);
        } else {
          //sexta_fila =  20 +  ((rendicion_list.length -15) * 8);
          doc.line(primera_columna, +sexta_fila - 5, 200, sexta_fila - 5);
        }

        //titulos verticales
        // doc.setFont("Helvetica", "bold");
        //doc.text('Rendiciones', primera_columna, quinta_fila + 16);
        // doc.text('Pagos adicionales', primera_columna, quinta_fila + 24);

        // valores

        // doc.text('0', segunda_columna, quinta_fila + 24);
        //
        //doc.text('$ 62.586,65', tercer_columna, quinta_fila + 16);
        // doc.text('$ 0,00', tercer_columna, quinta_fila + 24);
        //
        //doc.text("4555", cuarta_columna, quinta_fila + 16);
        // doc.text('$ 0,00', cuarta_columna, quinta_fila + 24)

        //Lineas

        // doc.line(primera_columna, quinta_fila+2 , 200, quinta_fila+2);
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(10);
        doc.text('Total ', sexta_columna, sexta_fila);
        doc.text(
          this.currencyPipe.transform(
            response.importe_liquidado / 100,
            'CAD',
            '$',
            '2.2-2',
            'es-ARS'
          ),
          sep_columna,
          sexta_fila
        );
        // doc.line(primera_columna, quinta_fila + 10, anchoLinea, quinta_fila + 10);
        // doc.line(primera_columna, quinta_fila + 18, anchoLinea, quinta_fila + 18);

        doc.text('S.E.U.O', sep_columna, sexta_fila + 8);

        doc.save(`liquidacion-nro-${response.id}-pdf.pdf`);
        // doc.autoPrint();
        // doc.output('dataurlnewwindow');
      })
      .catch((error) => {
        // this.datosTabla = error;

        console.log('ERROR', error);
        //Limpia la consola
      });
  }

  calcularQuintaFila(retenciones, resumen_conceptos2, importe_cuentas_valores) {
    const combinaciones = {
        '0-0-0': 150,
        '0-0-1': 153,
        '0-0-2': 160,
        '1-0-0': 154,
        '1-1-0': 160,
        '2-0-0': 160,
        '3-0-0': 165,
        '0-1-0': 153,
        '0-2-0': 162,
        '1-0-1': 162,
        '1-0-2': 170,
        '2-1-0': 170,
        '1-2-0': 170,
        '2-0-1': 170,
        '3-0-1': 175,
        '2-2-0': 175,
        '2-0-2': 175,
        '3-0-2': 180,
        '1-1-1': 170,
        '1-2-1': 178,
        '3-1-0': 178,
        '2-1-1': 178,
        '2-2-1': 183,
        '2-2-2': 192,
        '3-1-1': 180,
        '3-2-0': 180,
        '3-2-1': 192,
        '3-2-2': 192,
        '1-1-2': 178,
        '1-2-2': 188,
        '2-1-2': 183,
        '3-1-2': 192,
        '0-1-2': 178,
        '0-2-2': 185,
        '0-1-1': 170,
        '0-2-1': 180
    };

    const key = `${retenciones.length}-${resumen_conceptos2.length}-${importe_cuentas_valores.length}`;
    
    // Devolver el valor de la combinación o un valor por defecto si no existe
    return combinaciones[key] || 150; 
}

  exportarExcel(emitExportExcelLiq: ILiquidacion) {
    this._api
      .liquidacion_detalle(emitExportExcelLiq.id)
      .then((response: any) => {
        var dataFormated = Array<any>();
        const not_found = 'No disponible';
        const imp_found = '0,00';
        const valoresExcel = Array<any>();

        let importeSirtacNoEsNull = response.some(
          (objeto) => objeto.importe_sirtac !== null
        );
        let importeRetIvaNoEsNull = response.some(
          (objeto) => objeto.importe_ret_iva !== null
        );
        let importeRetGananciaNoEsNull = response.some(
          (objeto) => objeto.importe_ret_ganancia !== null
        );
        response.forEach((element) => {
          let cuotas;
          const valores = {};
          if (element.cuotas === 0) {
            cuotas = not_found;
          } else {
            cuotas = element.cuotas;
          }
          var dataAux: any = null;
          let porcentaje: any =
            (element.importe_gasto_comision / element.importe_pagado) * 100;

          valores['Número'] = element.id_sp != null ? element.id_sp : not_found;
          valores['Cliente'] =
            element.nombre_legal != null ? element.nombre_legal : not_found;
          valores['Descripción'] =
            element.descripcion != null ? element.descripcion : not_found;
          valores['Estado'] =
            element.estado != null ? element.estado : not_found;
          valores['Medio de pago'] =
            element.medio_pago != null ? element.medio_pago : not_found;
          valores['Fecha de creación'] =
            element.fecha_creacion != null
              ? this.datePipe.transform(element.fecha_creacion, 'dd-MM-yyyy')
              : not_found;
          valores['Fecha acreditación'] =
            element.fecha_acreditacion != null
              ? this.datePipe.transform(
                  element.fecha_acreditacion,
                  'dd-MM-yyyy'
                )
              : not_found;
          valores['Fecha de importe'] =
            element.fecha_importe != null
              ? this.datePipe.transform(element.fecha_importe, 'dd-MM-yyyy')
              : not_found;
          valores['Importe'] =
            element.importe != null ? element.importe / 100 : 0;
          valores['Importe pagado'] =
            element.importe_pagado != null ? element.importe_pagado / 100 : 0;
          valores['Importe comisión'] =
            element.importe_comision != null
              ? element.importe_comision / 100
              : 0;
          valores['Importe Gastos'] =
            element.importe_gasto_comision != null
              ? element.importe_gasto_comision / 100
              : 0;
          valores['Porcentaje Gastos'] =
            parseFloat(porcentaje).toFixed(2).toString().replace('.', ',') +
            '%';
          valores['Contracargo'] =
            element.importe_cc != null ? element.importe_cc / 100 : 0;
          (valores['Contracargo'] = 0),
            (valores['1er Vencimiento'] =
              element.fecha_vencimiento != null
                ? this.datePipe.transform(
                    element.fecha_vencimiento,
                    'dd-MM-yyyy'
                  )
                : not_found),
            (valores['2do Vencimiento'] =
              element.segunda_fecha_vencimiento != null
                ? this.datePipe.transform(
                    element.segunda_fecha_vencimiento,
                    'dd-MM-yyyy'
                  )
                : not_found),
            (valores['Referencia externa'] =
              element.referencia_externa != null
                ? element.referencia_externa
                : not_found);
          valores['Cuotas'] = cuotas;
          valores['Rendición Nº'] = element.id_rendicion;
          valores['Liquidación Nº'] = emitExportExcelLiq.id;
          if (importeSirtacNoEsNull) {
            valores['SIRTAC'] =
              element.importe_sirtac != null
                ? element.importe_sirtac / 100
                : not_found;
          }
          if (importeRetIvaNoEsNull) {
            valores['Ret. IVA'] =
              element.importe_ret_iva != null
                ? element.importe_ret_iva / 100
                : not_found;
          }
          if (importeRetGananciaNoEsNull) {
            valores['Ret. ganancias'] =
              element.importe_ret_ganancia != null
                ? element.importe_ret_ganancia / 100
                : not_found;
          }
          valores['Proveedor QR'] = element.proveedor_qr != null ? element.proveedor_qr : not_found;
          dataFormated.push(dataAux);
          valoresExcel.push(valores);
        });

        //Exportar
        var ws = XLSX.WorkSheet;
        ws = XLSX.utils.json_to_sheet(valoresExcel);
        var wb = XLSX.WorkBook;
        wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja');
        XLSX.writeFile(
          wb,
          'rendicion-n-' + emitExportExcelLiq.id + '-xlsx.xlsx'
        );
      })
      .catch((err) => {
        console.log(`Error al exportar rendiciones resultados!! -> ${err}`);
      });
  }


  
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LiquidacionService } from 'src/app/services/liquidacion.service';
import {
  ILiquidacion,
  ILiquidacionResponse,
  LiquidacionDetalle,
} from '../../models/liquidacion';
import {
  ILiquidacionEditableValues,
  LiquidacionStatus,
} from '../../models/extras';
import Swal from 'sweetalert2';
import { animate, style, transition, trigger } from '@angular/animations';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
@Component({
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.css'],
  animations: [
    trigger('onOff', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate(400),
      ]),
      transition(':leave', [
        animate(400, style(false ? { opacity: 0, height: 0 } : { opacity: 0 })),
      ]),
    ]),
  ],
})
export class DetalleLiquidacionComponent implements OnInit {
  // Utilities
  public loading: boolean = true;
  public isFormVisible: boolean = false;
  public editionForm: UntypedFormGroup;
  // Data Objects
  liquidacion: ILiquidacion;
  detalles: LiquidacionDetalle;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _Liquidacion: LiquidacionService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.makeFormGroup;
    this.getLiquidacionData();
  }

  /**
   * Genera el formGroup con o sin datos
   */
  makeFormGroup(properties: ILiquidacionEditableValues | {}) {
    this.editionForm = this.formBuilder.group({});

    // Iteramos sobre las propiedades existentes para poder rellenarlas
    for (const property in properties) {
      if (properties.hasOwnProperty(property)) {
        const value = properties[property] / 100;
        const validators: ((control: AbstractControl) => ValidationErrors)[] = [
          Validators.required,
        ];
        this.editionForm.addControl(
          property,
          this.formBuilder.control(value, validators)
        );
      }
    }
  }

  /**
   * Datos puros de la liquidacion
   */
  getLiquidacionData() {
    const id = +this._route.snapshot.paramMap.get('id');
    this._Liquidacion.getByID(id).subscribe({
      next: this.getDataSuccessHandler.bind(this),
      error: this.errorHandler.bind(this),
    });
  }

  /**
   * Datos mas relacionados a los movimientos y rendiciones asociadas a la liquidacion
   * @param liquidacion
   */
  getExtraData(liquidacion: ILiquidacion) {
    this._Liquidacion.getDetailByID(liquidacion.id).subscribe({
      next: this.getDetailSuccessHandler.bind(this),
      error: this.errorHandler.bind(this),
    });
  }

  getDetailSuccessHandler(response: ILiquidacion) {
    this.detalles = response[0];
  }

  getDataSuccessHandler(response: ILiquidacionResponse) {
    this.liquidacion = response.content[0] as ILiquidacion;
    this.loading = false;
    console.log(
      '%cdetalle.component.ts line:108 liquidacion',
      'color: #007acc;',
      this.liquidacion
    );
    const {
      importe_contracargo,
      importe_comision,
      importe_iibb_misiones,
      importe_iva,
      importe_liquidado,
      importe_ret_ganancia,
      importe_ret_iva,
      importe_sirtac,
      importe_total,
      importe_gasto_comision,
    } = this.liquidacion;
    this.makeFormGroup({
      importe_contracargo,
      importe_comision,
      importe_iibb_misiones,
      importe_iva,
      importe_gasto_comision,
      importe_total,
      importe_liquidado,
      importe_ret_ganancia,
      importe_ret_iva,
      importe_sirtac,
    });
    this.getExtraData(this.liquidacion);
  }

  errorHandler(error) {
    Swal.fire(
      'Error',
      'Ocurrio un error, por favor intentelo de nuevo mas tarde, si el problema persiste, contacte a soporte para mas informacion. <br> COD-ERROR: ' +
        error.error,
      'error'
    );
  }

  volver() {
    this._router.navigate(['dashboard/liquidacion']);
  }

  makePDF(liquidacionObject: ILiquidacion) {
    this._Liquidacion.makePDF(
      liquidacionObject.id,
      liquidacionObject.cliente.id
    );
  }

  makeExcel(liqui: ILiquidacion) {
    this._Liquidacion.exportarExcel(liqui);
  }

  /**
   * Envia el status siguiente al que tiene la liquidacion actual segun el status
   * @param status
   * @returns
   */
  handleLiqStatus(status: LiquidacionStatus): LiquidacionActionButton {
    const statusArr: LiquidacionActionButton[] = [
      {
        id: 'GENERADA',
        title: 'Generado',
        action: this.changeStatusProcess.bind(this),
      },
      {
        id: 'VERIFICADA',
        title: 'Generar verificacion',
        action: this.changeStatusProcess.bind(this),
      },
      {
        id: 'APROBADA',
        title: 'Generar aprobacion',
        action: this.changeStatusProcess.bind(this),
      },
      {
        id: 'REVISION',
        title: 'Solicitar revision',
        action: this.changeStatusProcess.bind(this),
      },
    ];
    const actualIndex = statusArr.findIndex(
      (statItem) => statItem.id === status
    );

    return statusArr[actualIndex + 1] ?? statusArr[actualIndex];
  }

  changeStatusProcess(id: number | string, status: LiquidacionStatus) {
    this._Liquidacion.changeProcessStatus(id, status).subscribe({
      next: this.successLiqProcess.bind(this),
      error: this.errorHandler.bind(this),
    });
  }

  successLiqProcess() {
    Swal.fire('OK', 'Proceso exitoso', 'success');
    this.getLiquidacionData();
  }

  openForm() {
    this.isFormVisible = !this.isFormVisible;
  }

  submitImportesForm(values: ILiquidacionEditableValues) {
    const id = +this._route.snapshot.paramMap.get('id');
    const multipliedValues = {} as ILiquidacionEditableValues;

    // Multiplicar las propiedades numéricas por 100
    for (const property in values) {
      if (values.hasOwnProperty(property)) {
        const value = values[property];
        if (typeof value === 'number') {
          multipliedValues[property] = value * 100;
        } else {
          multipliedValues[property] = value;
        }
      }
    }

    const completeObject = {
      ...multipliedValues,
      id,
    };

    if (!this.editionForm.invalid) {
      this._Liquidacion.updateLiquidacionValues(completeObject).subscribe({
        next: (response) => {
          Swal.fire(
            'OK',
            'Los datos han sido modificados exitosamente',
            'success'
          );
          this.isFormVisible = false;
          this.getLiquidacionData();
        },
        error: this.errorHandler.bind(this),
      });
    } else {
      Swal.fire(
        'Incompleto',
        'Se encontraron campos incompletos, por favor revisar los campos e intenar nuevamente <br><br> En caso de no necesitar el campo, por favor rellenarlo con un "0"',
        'warning'
      );
    }
  }
}

interface LiquidacionActionButton {
  id: LiquidacionStatus;
  title: string;
  action: (id: number | string) => void;
}
